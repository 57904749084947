// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.cursor-pointer {
  cursor: pointer;
}

.ml-auto, .mx-auto {
  margin-right: unset !important;
}

.danger-shadow {
  box-shadow: 0px 0px 6px $red-color;
}

.border-bottom-main {
  border-bottom: 1px solid $main-color;
}

.hover-background-color {
  border-radius: 5px;
  &:hover {
    background-color: $light-gray-color;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
  .dropdown-item {
    a{
      color: $white-color;
    }
  }
}

.copyright-font {
  font-size: 14px;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-5 {
  opacity: 0.5;
}

.light-hr {
  border-top: 1px solid $white-color;
  opacity: 0.2;
}

.silver-hr {
  border-top: 1px solid $gray-color;
  opacity: 0.3;
}

.faded-silver-bg {
  background-color: $faded-silver-color;
}
.text-grey{
  color:$gray-color
}
.text-grey-light{
  color:$light-gray-color;
}
html[dir="rtl"] {
  .dropdown-menu-right {
    right: auto;
    left: 0;
  }
}

.modal-close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  cursor: pointer;
}

.w {
  &-90 {
    width: 90% !important;
  }
  &-80 {
    width: 80% !important;
  }
  &-70 {
    width: 70% !important;
  }
  &-60 {
    width: 60% !important;
  }
  &-40 {
    width: 40% !important;
  }
  &-30 {
    width: 30% !important;
  }
  &-20 {
    width: 20% !important;
  }
  &-10 {
    width: 10% !important;
  }
}
.dark-color{
  color:#191919;
}
.text-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $light-gray-color;
  line-height: 0.1em;
  margin: 20px 0 20px;
  span {
    background: $silver-color;
    padding: 0 10px;
  }
}

.css-13cymwt-control{
  height: 50px;
  // border: 2px solid #e8e9f3;
  // border-radius: 10px;
  // transition: 0.1s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.css-13cymwt-control{
  border: 2px solid #e8e9f3 !important;
  border-radius: 10px !important;
  transition: 0.1s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
}
.custom-date-input{
  
  height: 50px;
  border: 2px solid #e8e9f3;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  background: #e9ecef;

}