.login {
    position: relative;
    padding: 0 1rem;
    // background: linear-gradient(185deg,$main-color 0%, rgba(34, 193, 195, 1) 100%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .form_login {
        padding: 1rem;
        background-color: rgba(202, 208, 208, 0.2);
        border-radius: 20px;
        // overflow: hidden;

        .logo_lang {
            text-align: center;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            img {
                width: 8rem;
            }

            .language {
                .lang {
                    width: 3rem;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {
.login {
        .form_login {
            // padding: 2rem;
            width: 30%;
        }
    }
}