.first_table {
  padding: 0 1rem;

  .form_table {
    // display: flex;
    // justify-content: center;
    // margin: 2rem 0;
  }
}
.pdf-table {
  thead {
    tr {
      color: #ba2928;
      border: 2px solid #ba2928;
      font-weight: bold;
      font-size: .8rem;
      th {
        border: 2px solid #ba2928;
      }
    }
  }
  tbody {
    tr {
      //   border-top: 4px solid #ba2928;
      border-left: 2px solid #ba2928;
      border-right: 2px solid #ba2928;
      border-bottom: 2px dashed #ba2928;
      font-size: .8rem;

      &:last-child {
        border-bottom: 2px solid #ba2928;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .first_table {
    padding: 0 4rem;
  }
}
