.error-border {
  border: 1px solid red;
  border-radius: 10px;
}

/* CSS for error label */
.error-label {
  color: red;
  font-size: 14px;
}
