@function rem($value, $base) {
  @if type-of($value) == "number" {
    @return ($value / $base) * 1rem;
  }
}
@mixin trim($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin ease {
  transition: all 0.3s ease;
}
@mixin imageBackground($url) {
  background-image: url(#{$url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
  // @mixin fontBold() {
  //   font-family: aktiv-grotesk-arabic, sans-serif;
  //   font-weight: 700;
  //   font-style: normal;
  // }
  // @mixin fontRegular() {
  //   font-family: aktiv-grotesk-arabic, sans-serif;
  //   font-weight: 400;
  //   font-style: normal;
  // }
  // @mixin fontMedium() {
  //   font-family: aktiv-grotesk-arabic, sans-serif;
  //   font-weight: 500;
  //   font-style: normal; 
  // }