.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color:#407fff;
}

.nav-tabs .nav-link {
    color: #495057;
}
.printable-content{
    p, li, ins{
        font-size: 13px;
    }
}
.printable-content{
    img{
        background-color: #fff;
    }
    .body-content{
        min-height: 710px;
        // overflow: hidden;
        margin-top: 160px;
    }
    .bg-photo{
      background-image: url("../../../images/Logo-opacityy.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      // min-height: 710px;
    }
}
.body-style{
    background-image: url("../../../images/Logo-opacityy.png");
    background-size: 39%;
    min-height: 710px;
    background-repeat: no-repeat;
    background-position: center;
    // overflow: hidden;
}

.unauth-body-style{
  background-image: url("../../../images/Logo-opacityy.png");
  background-size: 39%;
  opacity: "2";
  min-height: 710px;
  background-repeat: no-repeat;
  background-position: center;
  // overflow: hidden;
}

.pdf-document {
  display: 'block';
  // page-break-inside: avoid;
  }
  
  @media print {
    .pdf-document {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    // .pagebreak { page-break-before: always; } 
    .pdf-header,
    .pdf-footer {
      position: fixed;
      left: 0;
      right: 0;
      height: 200px;
      text-align: center;
      display: block;

      img{
        width: 100%;
        height: 100%;
        display: block;

      }
    }
    .pdf-footer{
      margin-top: 160px;
    }
  
    .pdf-header {
      top: 5px;
    }
  
    .pdf-footer {
      bottom: 0;
      // page-break-before: always;
    }
  
    .pdf-header,
    .pdf-footer,
    .pdf-header + * {
      page-break-before: always;
    }
  
    .pdf-footer,
    .pdf-footer + * {
      page-break-after: always;
    }
  }