.react-datepicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #212529; /* Dark background color */
  color: #9b9d9f;
  border: 1px solid #dd2c00; /* Red border */
  padding: 10px; /* Add padding to the container */
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__header {
  text-align: center;
  background-color: #212529;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

/* Style the date picker input field */
.react-datepicker__input-container {
  flex: 1;
  margin-right: 10px;
}

/* Style the time picker container */
.react-datepicker__time-container {
  flex: 1;
  background-color: #212529; /* Dark background color for time container */
}

/* Style the time picker input */
.react-datepicker-time__input {
  width: 100%;
  height: 40px; /* Increase the height of the input box */
  background-color: #212529; /* Dark background color */
  border: 1px solid #dd2c00; /* Red border */
  padding: 5px; /* Add padding to the input */
}
.react-datepicker__time-list {
  background-color: #212529; /* Dark background color */
}
.react-datepicker__time-list-item:hover {
  color: #212529;
}
/* Style the selected date */
.react-datepicker__day--selected {
  background-color: #dd2c00; /* Red color for selected date */
  color: #fff; /* Text color for selected date */
}
.react-datepicker__day-name,
.react-datepicker__day {
  color: #9b9d9f;
}
.react-datepicker__day:hover {
  color: #212529;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #9b9d9f;
}
