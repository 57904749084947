//dropdown
.input-group-merge {
  .input-group-text {
    background: none;
    border-radius: 10px 0 0 10px;
    border: 2px solid #e8e9f3;
    border-right: none;
  }
  &.input-group-calender {
    min-width: max-content;
    span {
      &.iticket-icon-calendar {
        font-size: 22px;
      }
    }
    input {
      margin: 0 !important;
      border: 2px solid #e8e9f3 !important;
      border-left: none !important;
      border-radius: 0 10px 10px 0 !important;
    //   width: 133px !important;
      &::placeholder {
        color: #191a23;
        font-size: 16px;
      }
    }
    .rmdp-range {
        background-color: rgba(25, 26, 35, 0.05);
        box-shadow: none;
        color: #5A5E6E;
    }
  }
}
.react-datepicker__month-container {
    float: none;
}
html[dir="rtl"] {
  .input-group-merge {
    .input-group-text {
      border-radius: 0 10px 10px 0;
      border: 2px solid #e8e9f3;
      border-left: none;
    }
    &.input-group-calender {
      input {
        border-right: none !important;
        border-left: 2px solid #e8e9f3 !important;
        border-radius: 10px 0 0 10px !important;
      }
    }
  }
}
