.multi-select {
    position: relative;
    width: 100%; /* Ensure it takes full width */
  }
  
  .multi-select-input {
    border: 1px solid #ccc;
    padding: 10px; /* Adjust padding for better appearance */
    cursor: pointer;
    width: 100%; /* Ensure it takes full width */
    display: flex;
    justify-content: space-between; /* Space between text and icon */
    align-items: center; /* Align items vertically */
    border-radius: 4px; /* Rounded corners */
    background-color: #fff; /* White background */
    transition: border-color 0.2s ease; /* Transition for border color */
  }
  
  .multi-select-input.focused {
    border-color: #007bff; /* Change this color as needed */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
  }
  
  .dropdown-icon {
    margin-left: 10px; /* Space between text and icon */
    color: #007bff; /* Icon color */
  }
  
  .multi-select-dropdown {
    position: absolute;
    z-index: 10;
    background: white;
    border: 1px solid #ccc;
    max-height: 200px; /* Set max height for dropdown */
    overflow-y: auto; /* Enable scrolling when overflow */
    width: 100%; /* Full width of the input */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add shadow for dropdown */
  }
  
  /* Positioning the dropdown based on its state */
  .multi-select-dropdown.below {
    top: 100%; /* Position below the input */
    left: 0;
  }
  
  .multi-select-dropdown.above {
    bottom: 100%; /* Position above the input */
    left: 0;
  }
  
  .multi-select-option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease; /* Transition for hover effect */
  }
  
  .multi-select-option:hover {
    background: #f0f0f0; /* Change hover background */
  }
  
  .multi-select-option input[type="checkbox"] {
    margin-right: 12px; /* Space between checkbox and label */
    cursor: pointer; /* Ensure the cursor is a pointer for better UX */
  }
  
  .multi-select-search {
    width: 100%; /* Full width */
    padding: 8px; /* Padding for better appearance */
    border: 1px solid #ccc; /* Border style */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 8px; /* Space below the search input */
  }
  