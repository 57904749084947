// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
nav {
  .logo {
    width: 94px;
  }

  .user {
    position: relative;
    width: fit-content;

    &_icon {
      text-align: start;
      margin-bottom: .5rem;

      svg {
        font-size: 30px;
        color: $main-color;
      }
    }

    &_text {
      h6 {
        font-size: 12px;
      }

      a {
        color: $main-color;
        font-size: 10px;
        cursor: pointer;
      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 2px;
      background-color: $main-color;
      position: absolute;
      left: -7px;
      top: 0px;
      display: none;
    }
  }
}

@media only screen and (min-width: 1200px) {
nav {
    .user {
      &_icon {
        text-align: center;
        margin-bottom:0;
    }
      &::after {
        display: block;
      }
    }
  }
}
@media only screen and (min-width: 1600px) {

}

///////////////////////////////////// arabic

html[dir="rtl"] {
nav {
    .user {
      &::after {
        right: -7px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    header {
      .middle-side-container {
        a {
          margin-left: 35px;
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 1600px) {
    header {
      .middle-side-container {
        a {
          margin-left: 60px;
          margin-right: 0;
        }
      }
    }
  }
}

.navbar {
  border-bottom: 5px solid #dd2c00;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000; 
  
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #dd2c00;
}

.navbar-light .navbar-nav .nav-link.dropdown-item {
  color: #000000; 
}

.navbar-light .navbar-nav .nav-link.dropdown-item:hover {
  color: #dd2c00; 
  background-color: #f8f9fa; 
}